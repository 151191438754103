// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
// IMAGES
import LogoAncor from "../assets/logo-ancor.svg"
import LogoCrc from "../assets/logo-crc.svg"
import LogoGarmin from "../assets/logo-garmin.svg"
import LogoMoeller from "../assets/logo-moeller.svg"
import LogoPettit from "../assets/logo-pettit.svg"
import LogoRule from "../assets/logo-rule.svg"

// STYLES
const useStyles = makeStyles({
  heading: {
    fontSize: "1.8rem",
    fontWeight: 600,
  },
})

// COMPONENT FUNCTION
const MarineSuppliesPage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT
  return (
    <Layout title="Marine Supplies">
      <PageTitle title="MARINE SUPPLIES" />
      <Typography
        variant="h2"
        className={classes.heading}
        style={{ marginTop: "4rem" }}
      >
        All the brands you trust ...
      </Typography>
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ marginTop: "2rem", marginBottom: "4rem" }}
      >
        <Grid item xs={4} sm={2}>
          <LogoAncor />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LogoCrc />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LogoGarmin />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LogoMoeller />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LogoPettit />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LogoRule />
        </Grid>
      </Grid>
      <Typography
        variant="h2"
        className={classes.heading}
        style={{ marginBottom: "1rem" }}
      >
        and much more ...
      </Typography>
      <Typography paragraph>
        batteries, fiberglass, repair kits, line, tape, coolers, hull fittings,
        bearing grease, fuel tanks, deck plates, fenders, inflatables, paddles
        and oars, life vests, first aid, flares, trailer jacks, tires, zincs,
        shackles, blocks, cleats, batten, turnbuckles, anchors, fasteners,
        moorings, mooring balls, buoys, pickup buoys …
      </Typography>
    </Layout>
  )
}

export default MarineSuppliesPage
